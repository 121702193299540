<template>
	<!-- 이력서 등록요청 팝업 -->
	<div class="notice_popup">
		<div class="close" @click="$emit('close')">
			<img src="/images/close.png" alt="닫기"/>
		</div>
		<div class="tit">프로젝트 삭제하기</div>
		<div class="member"><span class="name"> &#039;{{this.param.projectNm}}&#039; 를</span><br/>정말로 삭제 하시겠습니까?</div>
		<div class="select-btn">
			<div class="btn" @click="$emit('close', false)">취소</div>
			<div class="btn" @click="$emit('close', true)">예</div>
		</div>
	</div>
</template>
<script>
export default {
	props: { param:Object },
	data() {
		return {
			data: {},
		};
	},
	mounted(){ 
		// console.log(this.param);
	},
	methods: {
	},
};
</script>
